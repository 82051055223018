// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-case-studies-santis-automating-secretarial-tasks-jsx": () => import("./../../../src/pages/case-studies/santis-automating-secretarial-tasks.jsx" /* webpackChunkName: "component---src-pages-case-studies-santis-automating-secretarial-tasks-jsx" */),
  "component---src-pages-features-automation-jsx": () => import("./../../../src/pages/features/automation.jsx" /* webpackChunkName: "component---src-pages-features-automation-jsx" */),
  "component---src-pages-features-billing-jsx": () => import("./../../../src/pages/features/billing.jsx" /* webpackChunkName: "component---src-pages-features-billing-jsx" */),
  "component---src-pages-features-compose-letters-jsx": () => import("./../../../src/pages/features/compose-letters.jsx" /* webpackChunkName: "component---src-pages-features-compose-letters-jsx" */),
  "component---src-pages-features-online-booking-jsx": () => import("./../../../src/pages/features/online-booking.jsx" /* webpackChunkName: "component---src-pages-features-online-booking-jsx" */),
  "component---src-pages-features-online-payment-jsx": () => import("./../../../src/pages/features/online-payment.jsx" /* webpackChunkName: "component---src-pages-features-online-payment-jsx" */),
  "component---src-pages-features-patient-portal-jsx": () => import("./../../../src/pages/features/patient-portal.jsx" /* webpackChunkName: "component---src-pages-features-patient-portal-jsx" */),
  "component---src-pages-features-practice-management-jsx": () => import("./../../../src/pages/features/practice-management.jsx" /* webpackChunkName: "component---src-pages-features-practice-management-jsx" */),
  "component---src-pages-features-revenue-enhancement-jsx": () => import("./../../../src/pages/features/revenue-enhancement.jsx" /* webpackChunkName: "component---src-pages-features-revenue-enhancement-jsx" */),
  "component---src-pages-for-clinics-jsx": () => import("./../../../src/pages/for-clinics.jsx" /* webpackChunkName: "component---src-pages-for-clinics-jsx" */),
  "component---src-pages-for-patients-jsx": () => import("./../../../src/pages/for-patients.jsx" /* webpackChunkName: "component---src-pages-for-patients-jsx" */),
  "component---src-pages-get-started-jsx": () => import("./../../../src/pages/get-started.jsx" /* webpackChunkName: "component---src-pages-get-started-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-migrating-from-specpack-jsx": () => import("./../../../src/pages/migrating-from-specpack.jsx" /* webpackChunkName: "component---src-pages-migrating-from-specpack-jsx" */),
  "component---src-pages-migrating-to-carebit-jsx": () => import("./../../../src/pages/migrating-to-carebit.jsx" /* webpackChunkName: "component---src-pages-migrating-to-carebit-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-security-jsx": () => import("./../../../src/pages/security.jsx" /* webpackChunkName: "component---src-pages-security-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */)
}

